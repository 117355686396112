<template>
  <div class="view-root flex-column">
    <div style="margin-bottom: 10px;display: flex; justify-content: space-between; align-items: center">
      <p class="common-title">客服消息</p>
    </div>
    <div class="message-box">
      <div class="message-box-userList">
        <div v-for="(item, index) in user_list" :key="index" @click="userClick(index)"
          class="message-box-userList-item">
          <img src="../../assets/login_logo.png" />
          <div class="message-box-userList-item-name">
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="message-box-messageList">
        <div class="message-box-messageList-title">
          {{ selectUser.name }}
        </div>

        <div class="message-box-content" ref="messageBoxContent">
          <MessageItem v-for="(item, i) in selectUser.messageList" :data="item" :userId="selectUser.user_id"
            :key="`msg_${i}`"></MessageItem>
        </div>

        <div class="message-send" v-if="selectUser.user_id">
          <div class="input">
            <el-input style="border-radius: none;" v-model="messageStr" @keyup.enter="handleEnter"
              placeholder="说点什么吧"></el-input>
          </div>
          <div class="send-button" @click="sendMessage">发送消息</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getPartnerMessageUsers,
  getServiceMessagesByDialogue,
  getTickets,
  sendServiceMessage,
} from "../../api/api"
import { animate } from "popmotion"
import "../../assets/common/common.css"
import MessageItem from './components/message.vue'
import { getUserId } from "../../utils/store"
export default {
  name: "index",
  components: {
    MessageItem
  },
  data() {
    return {
      meeting_id: this.$route.query.meeting_id,
      dataList: [],
      tickets: [],
      showLinkDialog: false,
      shareInfo: {
        user_email: "",
        ticket_id: "",
        shareUrl: "",
        showShareUrl: false,
      },
      user_list: [
        {
          name: "test",
          user_id: "65f68ca2a4640b8a6f8b4573",
          newMessahe: "nihao",
          messageList: ["nihao", "niyehao", "zaijian"],
        },
        {
          name: "test1",
          user_id: "",
          newMessahe: "nihao1",
          messageList: ["在嘛", "你好", "zaijian"],
        },
      ],
      selectUser: {},
      user_id: getUserId(),
      messageStr: "",
    }
  },
  mounted() {
    this.fetchData()
    this.requestTickets()
    this.initSocket()
  },
  beforeDestroy() {
    if (this.client) {
      this.client.close()
    }
  },
  methods: {

    handleEnter() {
      if (this.messageStr && this.messageStr != '') {
        this.sendMessage()
      }
    },

    sendMessage() {
      let messageToSend = JSON.stringify({
        msg: "客服消息",
        type: "serviceMessage",
        meeting_id:this.meeting_id,
        senderID: this.user_id,
        receiveID: this.selectUser.user_id,
        message: this.messageStr,
        message_time: parseInt(Date.now() / 1000),
      })
      const messageJson = JSON.parse(messageToSend)
      const showTimeMessageList = this.selectUser.messageList.filter(item => item.showTime)
      if (showTimeMessageList.length) {
        if (this.needShowTime(showTimeMessageList.pop().message_time)) {
          messageJson.showTime = true
        }
      } else {
        messageJson.showTime = true
      }

      this.selectUser.messageList.push(messageJson)
      this.scrollToEnd()
      this.client.send(messageToSend)
      sendServiceMessage(messageToSend)
      this.messageStr = ""
    },
    initSocket() {
      this.client = new WebSocket("wss://team-chat.learningfirst.cn:9021")
      this.client.onopen = () => {
        console.log("WebSocket 9021 连接成功 登录id：" + this.user_id)
        this.client.send(JSON.stringify({
          type: "login",
          userId: this.user_id,
        }))
      }
      let that = this
      this.client.onmessage = (e) => {
        let obj = JSON.parse(e.data)
        console.log(e.data)
        if (obj.msg === "客服消息") {
          console.log("WebSocket 9021 收到一条客服消息")
          that.handleKefuMsgReceived(obj)
        }
      }
      this.client.onclose = () => {
        this.$message.error("消息WebSocket连接已断开")
        console.log("WebSocket 9021 连接关闭")
        this.client = null
      }
    },
    handleKefuMsgReceived(obj) {
      let messageStr = obj.data;
      let messageData = { message: messageStr, message_time: parseInt(Date.now() / 1000), showTime: false  }

      if (this.needShowTime(messageData.message_time)) {
        messageData.showTime = true
      }
      if(obj.senderID == this.selectUser.user_id) {
        this.selectUser.messageList.push(messageData)
        this.scrollToEnd()
      }
    },
    needShowTime (message_time, prevTime = 0) {
      if (!message_time) return false
      if (!prevTime) {
        prevTime = message_time
        message_time = parseInt(Date.now() / 1000)
      }
      return (message_time - prevTime > 5 * 60)
    },
    userClick(index) {
      this.selectUser = this.user_list[index]
      this.selectUser.messageList = []
      console.log("this.selectUser", this.selectUser)

      let talkers = [this.selectUser["user_id"], getUserId()]
      getServiceMessagesByDialogue(talkers).then((res) => {
        let prevTime = 0
        const messageList = res.data.data
        messageList.forEach(message => {
          // 每隔5分钟显示的第一条消息显示时间
          if (this.needShowTime(message.message_time, prevTime)) {
            prevTime = message.message_time
            message.showTime = true
          }
        })
        this.selectUser.messageList = messageList
        this.scrollToEnd()
      })
    },
    requestTickets() {
      getTickets(this.meeting_id).then((res) => {
        this.tickets = res.data.data
      })
    },
    fetchData() {
      // getMeetingParticipants(this.meeting_id).then((res) => {
      //   this.dataList = res.data.data;
      // });
      getPartnerMessageUsers(getUserId()).then((res) => {
        this.user_list = res.data.data
      })
    },
    scrollToEnd() {
      this.$nextTick(() => {
        const container = this.$refs.messageBoxContent
        if (container) {
          animate({
            from: container.scrollTop,
            to: container.scrollHeight - container.clientHeight,
            duration: 300,
            onUpdate: (value) => {
              container.scrollTop = value
            },
          })
        }
      })
    },
    copyLink() {
      let content = this.shareInfo.shareUrl
      const input = document.createElement("input")
      input.value = content
      document.body.appendChild(input)
      input.select()
      document.execCommand("Copy")
      input.remove()
      this.$message.success("复制成功")
    },
    createShareUrl() {
      if (this.shareInfo.ticket_id == "") {
        this.$message.error("请选择门票")
        return
      }
      this.shareInfo.shareUrl =
        "http://canhaohui-fwh.reachable-edu.com/meetingForm?meeting_id=" +
        this.meeting_id +
        "&ticket_id=" +
        this.shareInfo.ticket_id
      if (this.shareInfo.user_email) {
        this.shareInfo.shareUrl =
          this.shareInfo.shareUrl + "&user_email=" + this.shareInfo.user_email
      }
      this.shareInfo.showShareUrl = true
    },
    clickShowLinkDialog() {
      this.shareInfo.ticket_id = ""
      this.shareInfo.shareUrl = ""
      this.shareInfo.user_email = ""
      this.shareInfo.showShareUrl = false
      this.showLinkDialog = true
    },
  },
}
</script>
<style scoped lang="scss">
.table-name {
  flex: 3;
}

.table-mobile {
  flex: 3;
}

.table-email {
  flex: 3;
}

.message-box {
  display: flex;
  height: 80vh;
  border-radius: 6px;
  overflow: hidden;

  .message-box-userList {
    width: 25%;
    border: 1px solid #f5f5f5;
    overflow-y: auto;

    .message-box-userList-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10px;
      height: 50px;
      border-bottom: 1px solid #f5f5f5;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        margin-left: 10px;
        margin-top: 10px;
        float: left;
      }

      .message-box-userList-item-name {
        margin-left: 10px;
        margin-top: 10px;
        font-size: 15px;
        float: left;
      }
    }
  }

  .message-box-messageList {
    width: 75%;
    border: 1px solid #f5f5f5;
    display: flex;
    flex-direction: column;

    &-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #e3e7eb;
      flex: 0 0 auto;
      padding: 0 12px;
    }

    .message-box-content {
      max-height: calc(80vh - 100px);
      flex: 1;
      overflow-y: auto;
      padding: 12px;

      .message-box-messageList-customer {
        text-align: right;
        margin: 10px 0;
        padding-right: 20px;
      }

      .message-box-messageList-user {
        text-align: left;
        margin: 10px 0;
        padding-left: 12px;
      }
    }

    .message-send {
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      line-height: 40px;
      padding: 5px 10px;
      text-align: center;

      .input {
        width: 70%;
        background-color: #e0edfd;
        color: #9bcdff;
        border-radius: 5px 0 0 5px;
        font-size: 12px;
      }

      .send-button {
        width: 15%;
        flex: 0 0 auto;
        cursor: pointer;
        background-color: #0080ff;
        border-radius: 0 5px 5px 0;
        color: white;
        font-size: 12px;
        padding: 0 12px;
        white-space: nowrap;

      }
    }
  }
}
</style>
