<!------------------------------------------- template ------------------------------------------->
<template>
  <div>
    <div class="message-time" v-if="data.showTime">
      <div class="message-time-content">{{ formatDate(data.message_time) }}</div>
    </div>
    <div class="message-item" :class="{ customer: data.receiveID === userId }">
      <div class="message-item-content">{{ data.message }}</div>
    </div>
  </div>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script>
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday' 
dayjs.extend(isToday);
export default {
  name: '',
  components: {
  },

  props: {
    userId: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
    }
  },

  /* 一.生命周期函数 */
  created() {
  },

  /* 二.监控函数 */
  watch: {
  },

  computed: {
  },

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用函数注释
    actionXxx() {

    },

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    formatDate(dateTime) {
      const timestamp = dateTime * 1000

      let formatStr = 'YYYY-MM-DD HH:mm:ss'
      const messageDate = dayjs(timestamp)
      if (messageDate.isToday()) {
        formatStr = 'HH:mm:ss'
      }
      return messageDate.format(formatStr)
    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx() {

    }
  }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.message-time{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  &-content{
    font-size: 12px;
    padding: 3px 5px;
    background: #DEDEDE;
    color: #FFF
  }
}
.message-item {
  margin-bottom: 10px;
  display: flex;

  &-content {
    display: inline-flex;
    padding: 6px 10px;
    border-radius: 0 16px 16px 16px;
    background: #cdcdcd;
    color: #333;
    word-break: break-all;
    max-width: 70%;
  }

  &.customer {
    justify-content: flex-end;

    .message-item-content {
      border-radius: 16px 0 16px 16px;
      color: #FFF;
      background: #3185f2;
    }

  }
}
</style>
